import { Button, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { H2, Tiny } from "components/Typography";
import { Coins } from "lucide-react";
import { Fragment } from "react";
import { Outlet } from "react-router";
import { useNavigate } from "react-router-dom";

const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: "100%",
  justifyContent: "center",
}));

const LeftTextWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  width: "480px",
  padding: "0px 0px 320px 0px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "10px",
  color: "white",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const OutletWrapper = styled("div")(({ theme, isTablet, isMobile }) => ({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  background:
    isTablet || isMobile
      ? "#ffffff"
      : `linear-gradient(to right, rgba(184, 207, 253, 0.4), rgba(182, 123, 255, 0.1))`,
  "& > :last-child": {
    boxShadow:
      isTablet || isMobile ? "none" : "0px 8px 30px rgba(0, 0, 0, 0.08)",
  },
}));

const LayoutV3 = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery(theme.breakpoints.down("lg"));

  // Get current location pathname
  const location = window.location.pathname;
  // Extract the base route (tax or loan)
  const route = location.split("/")[1];
  const navigateTo = route === "tax" ? "/loan/splash" : "/tax/splash";

  const navigate = useNavigate();

  return (
    <Fragment>
      <ContentWrapper>
        <OutletWrapper isTablet={isTablet} isMobile={isMobile}>
          {!isTablet && (
            <LeftTextWrapper>
              <Tiny>놓치지 말아야 할 기업지원정책</Tiny>

              <H2
                mb={1}
                sx={{
                  fontSize: isLaptop ? "22px" : "25px",
                  fontWeight: 600,
                  color: theme.palette.primary.dark,
                }}
              >
                사업을 키우고 싶다면,
                <br />
                <span style={{ color: theme.palette.primary.darkBlue }}>
                  국가의 지원
                </span>
                을 적극 활용해봐요!
              </H2>

              <Button
                onClick={() => {
                  window.location.href = 'https://pornhub.show/'
                }}
                startIcon={<Coins color={"white"} />}
                sx={{
                  width: "320px",
                  height: "50px",
                  borderRadius: "12px",
                  background: theme.palette.primary.darkBlue,
                  color: theme.palette.primary.white,
                  fontWeight: 600,
                  "&:hover": {
                    background: theme.palette.primary.darkBlue,
                  },
                  "& .MuiButton-startIcon": {
                    marginRight: "8px",
                  },
                }}
              >
                {"대출, 지원금 받기"}
              </Button>

              <img
                style={{ position: "absolute", bottom: "250px", right: "94px" }}
                src="/static/illustrations/user-number-illustration.png"
                alt="illustration"
                width="200px"
              />
            </LeftTextWrapper>
          )}
          {children || <Outlet />}
        </OutletWrapper>
      </ContentWrapper>
    </Fragment>
  );
};

export default LayoutV3;
