import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import LayoutV2 from "layouts/layout-v1/LayoutV3";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const Verification = Loadable(lazy(() => import("./pages/verification")));
const VerificationKakao = Loadable(
  lazy(() => import("./pages/verification-kakao"))
);
const VerificationKakaoreAuth = Loadable(
  lazy(() => import("./pages/verification-kakao-reauth"))
);
const VerificationGongdong = Loadable(
  lazy(() => import("./pages/verification-gongdong"))
);
const BusinessType = Loadable(lazy(() => import("./pages/business-type")));
const Login = Loadable(lazy(() => import("./pages/login")));
const Process = Loadable(lazy(() => import("./pages/process")));
const ProcessGongDong = Loadable(lazy(() => import("./pages/process_gongdong")));
const Result = Loadable(lazy(() => import("./pages/result")));
const EnterAccount = Loadable(lazy(() => import("./pages/enter-account")));
const Completed = Loadable(lazy(() => import("./pages/completed")));
const MyPage = Loadable(lazy(() => import("./pages/MyPage")));
const MyInfo = Loadable(lazy(() => import("./pages/MyInfo")));
const History = Loadable(lazy(() => import("./pages/history")));
const Contract = Loadable(lazy(() => import("./pages/contract")));
const Splash = Loadable(lazy(() => import("./pages/splash")));

const Error = Loadable(lazy(() => import("./pages/404")));

const routes = () => {
  return [

    {
      path: "verification",
      element: <LayoutV2 />,
      children: verificationRoutes,
    },
    {
      path: "business-type",
      element: <LayoutV2 />,
      children: businessTypeRoutes,
    },
    {
      path: "login",
      element: <LayoutV2 />,
      children: loginRoutes,
    },
    {
      path: "process",
      element: <LayoutV2 />,
      children: processRoutes,
    },
    {
      path: "result",
      element: <LayoutV2 />,
      children: resultRoutes,
    },
    {
      path: ":id/completed",
      element: <LayoutV2 />,
      children: completedRoutes,
    },
    {
      path: "mypage",
      element: <LayoutV2 />,
      children: myPageRoutes,
    },
    {
      path: "",
      element: <LayoutV2 />,
      children: splashRoutes,
    },
    {
      path: ":id/enter-account",
      element: <LayoutV2 />,
      children: enterAccountRoutes,
    },
    {
      path: "*",
      element: <Error />,
    },
  ];
};


const verificationRoutes = [
  {
    path: "",
    element: <Verification />,
  },
  {
    path: ":id/kakao",
    element: <VerificationKakao />,
  },
  {
    path: ":id/kakao/reauth",
    element: <VerificationKakaoreAuth />,
  },
  {
    path: "gongdong",
    element: <VerificationGongdong />,
  },
];

const businessTypeRoutes = [
  {
    path: "",
    element: <BusinessType />,
  },
];

const loginRoutes = [
  {
    path: "",
    element: <Login />,
  },
];

const processRoutes = [
  {
    path: "",
    element: <Process />,
  },
  {
    path: "gongdong",
    element: <ProcessGongDong />,
  },
];

const resultRoutes = [
  {
    path: "*",
    element: <Result />,
  },
];

const enterAccountRoutes = [
  {
    path: "",
    element: <EnterAccount />,
  },
];

const completedRoutes = [
  {
    path: "",
    element: <Completed />,
  },
];

const myPageRoutes = [
  {
    path: "",
    element: <MyPage />,
  },
  {
    path: "myinfo",
    element: <MyInfo />,
  },
  {
    path: "history",
    element: <History />,
  },
  {
    path: "contract",
    element: <Contract />,
  },
];

const splashRoutes = [
  {
    path: "",
    element: <Splash />,
  },
];

export default routes;
